<template>
  <div>

    <CRow>
      <CCol lg="12">
        <CCard>
        <CCardHeader>
          <slot name="header">
            <h4 class="float-left">Pencarian </h4>
            <a href="/#/login" class="float-right btn btn-success ">Login</a>

          </slot>
        </CCardHeader>
        <CCardBody>

        <CRow>
              <CCol sm="12">
              <CSelect
                v-model="namadesa"
                label="Kelurahan"
                :options="kelurahans"
                placeholder="Cari Kelurahan"
              />
              </CCol>
              <CCol sm="12">
              <CInput
                v-model="nomor_haksu"
                label="Nomor Hak/SU"
                placeholder="Cari Nomor Hak/SU"
              />  
              </CCol>
              <CCol sm="12">
              <CInput
                v-model="nib"
                label="NIB"
                placeholder="Cari NIB"
              />  
              </CCol> 
              <CCol sm="12">
              <CInput
                v-model="nama_peminjam"
                label="nama_peminjam"
                placeholder="Nama Peminjam"
              />  
              </CCol> 
              <!-- <CCol sm="12"> 
              <CSelect
                v-model="aksi"
                label="Aksi (Pinjam/Print/Copy)"
                :options="options"
                placeholder="Pinjam"
              />
              </CCol>  -->
              <CCol sm='12' class='float-right text-right'>
                <CButton color="secondary" class='mr-3'>Reset</CButton>
                <CButton color="primary" @click="loadData()">Cari Arsip</CButton>
              </CCol>
        </CRow>
        

        
        <hr>

            <CAlert v-if="dataslength==0" show color="danger" class='text-center'>Kosong! Silahkan ketik kolom pencarian.</CAlert>

            <div v-if="dataslength>0">
              <div v-for="rs in pencarians" :key="rs.id_arsip" class="card mb-3">
                <div class="card-body">
                  <h5 class="card-title"><CBadge color="success">{{rs.tipe_hak}}</CBadge>  - <span class=""><b>{{rs.namadesa}}</b></span>
                    <span class="float-right">
                      <CButton variant="outline" size="sm"  @click="pinjamArsip(rs.id_arsip)"
                v-c-tooltip.hover.click="'Pinjam Arsip!'" class='mr-2' color="dark"><CIcon name="cil-transfer"/></CButton>
                      <CButton variant="outline" size="sm" 
                v-c-tooltip.hover.click="'Print Arsip!'" @click="openLink(rs.file)" class='mr-2' color="dark"><CIcon name="cil-print"/></CButton>
                      <CButton variant="outline"  
                v-c-tooltip.hover.click="'Download Arsip!'" @click="downloadData(rs.file)" size="sm" color="dark"><CIcon name="cil-copy"/></CButton>
                    </span>
                  </h5>
                  <hr>
                  <div class="row">
                    <div class="col-6"><b>NO HAK : {{rs.nomor_hak}}</b> <br>{{rs.status}}</div>
                    <div class="col-6 text-right" ><small><b>SU : {{rs.su}}</b><br>NIB : {{rs.nib}}</small></div>
                  </div>
                </div>
              </div>
            </div>


            <CSpinner v-show="mySpinner" color="success" style="position:fixed;left: 50%;top:50%"/>

            <CModal
              :show.sync="filenull"
              :no-close-on-backdrop="true"
              :centered="true"
              title="Warning"
              color="danger"
            >
              File tidak tersedia / belum diupload.
              <template #header>
                <h6 class="modal-title">Custom smaller modal title</h6>
                <CButtonClose @click="filenull = false" class="text-white"/>
              </template>
              <template #footer>
                <CButton @click="filenull = false" color="success">Ok</CButton>
              </template>
            </CModal>


    </CCardBody>
  </CCard>  
  
  </CCol>
  </CRow>

  </div>
</template>

<style scoped>

</style>
 
<script>
require('vue2-autocomplete-js/dist/style/vue2-autocomplete.css');
import Autocomplete from 'vue2-autocomplete-js'
import 'jquery/dist/jquery.min.js';
// import 'bootstrap/dist/css/bootstrap.min.css';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import axios from 'axios';
import $ from 'jquery'; 
import { CToast } from '@coreui/vue'
// const qz = require("qz-tray");
import qz from 'qz-tray';

export default {
  
  filters: {
    rupiah: function(value) {
      
      var formatter = new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',

      });

      return formatter.format(value); /* $2,500.00 */
    }
  },
  computed : {
    years () {
      const year = new Date().getFullYear()
      return Array.from({length: year - 2019}, (value, index) => new Date().getFullYear() - index)
    }
  },
  mounted(){
    qz.websocket.connect().then(function() {
       alert("Connected!");
    });
  },
  data: function() {
        return {
            gridColumns: ['No', 'Nomor Berkas', 'Tahun','Jenis Hak', 'Nomor Hak','Kegiatan','Atas Nama', 'Rak','Tgl'],
            headers: [
              {
                text: 'No',
                align: 'start',
                sortable: false,
                value: 'no',
              },
              { text: 'Nomor Berkas', value: 'nomor_berkas' },
              { text: 'Tahun', value: 'tahun_arsip' },
              { text: 'Jenis Hak', value: 'jenis_hak' },
              { text: 'Nomor Hak', value: 'nomor_hak' },
              { text: 'Kegiatan', value: 'kegiatan' },
              { text: 'Atas Nama', value: 'atas_nama' },
              { text: 'Rak', value: 'rak' },
              { text: 'Tgl - Tgl Kembali', value: 'tgl' },
            ],
            dataslength : 0,
            nomor_haksu:"",
            nib:"",
            mySpinner : false,
            filenull : false,
            namadesa:"Mariana",
            kelurahans:['Mariana'],
            products:[],
            options:['Pinjam','Print','Copy'],
            items:[],
            toasts: [],
            pencarians:[],
            itemlengths:0,
            tahun: new Date().getFullYear(),
        }
  },
  methods: {
    openLink: function(link) {
      var url = "http://139.180.219.157/earsip/api/uploads/"+link;
      if(link){
        window.open(url, '_blank');
      }
      else{
        this.filenull = true;
      }
    },
    pinjamArsip: function(id_arsip) {
      let us = JSON.parse(this.$store.state.users);
      const today = new Date();
      const yyyy = today.getFullYear();
      let mm = today.getMonth() + 1; // Months start at 0!
      let dd = today.getDate();
      let hh = today.getHours();
      let mn = today.getMinutes();

      if (dd < 10) dd = '0' + dd;
      if (mm < 10) mm = '0' + mm;
      if (hh < 10) hh = '0' + hh;
      if (mn < 10) mn = '0' + mn;

      var date = dd + '/' + mm + '/' + yyyy+' ' +hh+':'+mn;
       const arsip = { 
                          id_arsip: id_arsip, 
                          nama_peminjam: us.name,
                          };
      // alert(JSON.stringify(arsip));
      axios.post("http://139.180.219.157/earsip/api/public/addpeminjaman", arsip)
        .then((response) => {
            // console.log(response);
            // alert(response.data);
            if(response.data.status=='sukses'){
              // var url = "http://139.180.219.157/earsip/struk.php?id_arsip="+id_arsip;
              // window.open(url, '_blank');

              qz.printers.find("POS-58(copy of 2)").then(function(found) {
                 // alert("Printer: " + found);
                 var config = qz.configs.create(found);       // Create a default config for the found printer
                 var data = [
                       '\x1B' + '\x40',          // init
                       '\x1B' + '\x61' + '\x31', // center align
                       'Selamat Datang di eArsip ' + '\x0A',
                        '\x1B' + '\x61' + '\x30', // left align
                       'Buku tanah & Surat Ukur yang anda cari adalah ' + '\x0A',
                       'Nama Peminjam : '+us.name+' ' + '\x0A',
                       'Buku Tanah No :  ' +response.data.nomor_hak+ '\x0A',
                       'SU No     :  ' +response.data.su+ '\x0A',
                       'Kelurahan : ' +response.data.namadesa+ '\x0A',
                       ' ' + '\x0A',
                       'Berada di  ' + '\x0A',
                       'Ruangan   :  ' +response.data.ruangan+ '\x0A',
                       'Lemari    :  ' +response.data.lemari+ '\x0A',
                       'Kolom   :  ' +response.data.kolom+ '\x0A',
                       'Baris     :  ' +response.data.baris+ '\x0A',
                       'Bundel    :  ' + '\x0A',
                        '\x1B' + '\x61' + '\x31', // center align
                       'Terima Kasih ' + '\x0A',
                       date+ '\x0A'+'\x0A'+'\x0A',
                        // { type: 'raw', format: 'image', flavor: 'file', data: require('../../assets/images/denah.jpg'), options: { language: "ESCPOS", dotDensity: 'double' } },

                ];
                 return qz.print(config, data);
              });
              
            }
            else{
              this.myError = true;
            }
            
      });
      
    },
    printData: function(){
      let us = JSON.parse(this.$store.state.users);
      // qz.websocket.connect().then(function() { 
      //    return qz.printers.find("POS-58(copy of 2)");              // Pass the printer name into the next Promise
      // }).then(function(printer) {
      //    var config = qz.configs.create(printer);       // Create a default config for the found printer
      //    var data = ['^XA^FO50,50^ADN,36,20^FDRAW ZPL EXAMPLE^FS^XZ'];
      //    return qz.print(config, data);
      // }).catch(function(e) { console.error(e); });
      

    },
    downloadData: function(file){
      if(file){
          var link = "http://139.180.219.157/earsip/api/uploads/"+file;
          var rand = Math.floor(Math.random() * 100000);
          var dfile = rand+"_"+file;
             axios({
                  url: link, // File URL Goes Here
                  method: 'GET',
                  responseType: 'blob',
              }).then((res) => {
                   var FILE = window.URL.createObjectURL(new Blob([res.data]));
                   var docUrl = document.createElement('a');
                   docUrl.href = FILE;
                   docUrl.setAttribute('download', file);
                   document.body.appendChild(docUrl);
                   docUrl.click();
                  // alert(JSON.stringify(FILE));
                  const opts = {
                    suggestedName: file,
                    types: [{
                      accept: {'application/pdf': ['.pdf']},
                    }],
                  };
                  window.showSaveFilePicker(opts);
              });

      }
      else{
        this.filenull = true;
      }
      
    },
    loadData: function() {
      this.mySpinner = true;
      const cari = { 
              namadesa: this.namadesa, 
              nomor_haksu: this.nomor_haksu,
              nib: this.nib,
              };
      axios.post("http://139.180.219.157/earsip/api/public/cariarsip", cari)
        .then((response) => {

            console.log(response);
             this.pencarians = response.data;
             this.dataslength = response.data.length;
            this.mySpinner = false;
        })      
      
    }
  }
}
</script>